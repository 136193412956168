import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, Collapse, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { request } from "../../helpers/request";
import { MainContext } from "../../MainContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface CollapsableBoxProps {
    title: string;
    children: React.ReactNode; // Accept children as a prop
}

const CollapsableBox: React.FC<CollapsableBoxProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box sx={{ mb: 4, border: "2px solid #ccc", borderRadius: 3 }}>
            <Box
                onClick={() => setIsOpen(!isOpen)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 2,
                    cursor: "pointer",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 3,
                }}
            >
                <Typography variant="h6">{title}</Typography>
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse in={isOpen}>
                <Box sx={{ padding: 2 }}>{isOpen ? children : ""}</Box>
            </Collapse>
        </Box>
    );
};

const NewProp = () => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            type: "...",
        },
    });
    const { updateKeys } = useContext(MainContext);

    const onSubmit = (data: any) => {
        console.log("Form submitted with data:", data);
        request
            .post("/value", data)
            .then((res) => {
                updateKeys();
                reset();
            })
            .catch((err) => {});
    };

    const toggleCollapse = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <CollapsableBox title="New Prop">
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "15px", // Spacing between items
                    }}
                    component="fieldset"
                    margin="normal"
                >
                    <Controller name="name" control={control} rules={{ required: "Name is required" }} render={({ field }) => <TextField {...field} label="Name" error={!!errors.name} helperText={errors.name?.message} />} />

                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: "Type is required" }}
                        render={({ field }) => (
                            <Select {...field} error={!!errors.type} displayEmpty>
                                <MenuItem value="..." disabled>
                                    Select Type
                                </MenuItem>
                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                <MenuItem value="string">String</MenuItem>
                            </Select>
                        )}
                    />
                    <Button type="button" onClick={handleSubmit(onSubmit)} variant="outlined">
                        Create
                    </Button>
                </FormControl>
            </Box>
        </CollapsableBox>
    );
};

export default NewProp;

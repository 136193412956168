import React, { useEffect } from "react";
import { Box, FormControl, FormHelperText, FormLabel, Input, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ILocation } from "./PropertyInput";
import MapComponent from "./Map";

interface LocationInputProps {
    onChange: (event: { target: { name: string; value: ILocation } }) => void;
}

const LocationInput: React.FC<LocationInputProps> = ({ onChange }) => {
    const { register, setValue, watch, control } = useForm<ILocation>();

    // Watch for changes in form fields
    const locationFields = watch();

    // Notify the parent when locationFields change
    useEffect(() => {
        console.log(locationFields);
        onChange({
            target: {
                name: "location",
                value: locationFields,
            },
        });
    }, [locationFields, onChange]);

    return (
        <Box sx={{ borderRadius:"5px", margin:"20px 0"}}>
            <Box>
                <Controller
                    name="lat"
                    control={control}
                    render={({ field }) => (
                        <MapComponent
                            {...field}
                            onChange={(location: ILocation) => {
                                Object.entries(location).map(([key, value]) => {
                                    console.log(key, value)
                                    //@ts-ignore
                                    setValue(key, value)
                                })
                            }}
                        />
                    )}
                />
                <FormControl margin="normal" variant="standard" sx={{ marginRight: "5px" }}>
                    <TextField {...register("country", { required: true })} />
                    <FormHelperText>Country</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("region", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Region</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("city", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>City</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("district", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>District</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("street", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Street</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("building", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Building</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <TextField {...register("apartment")} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Apartment</FormHelperText>
                </FormControl>
                <br />
                <FormControl margin="normal" variant="standard">
                    <Input {...register("lat", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Latitude</FormHelperText>
                </FormControl>
                <FormControl margin="normal" variant="standard">
                    <Input {...register("lng", { required: true })} sx={{ marginRight: "5px" }} />
                    <FormHelperText>Longitude</FormHelperText>
                </FormControl>
            </Box>
        </Box>
    );
};

export default LocationInput;

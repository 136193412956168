import React from "react";
import { AppBar, Toolbar, Button, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  return (
    <AppBar position="sticky" sx={{ mb: 4 }}>
      <Toolbar>
        {/* Logo Section */}
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" sx={{ color: "white" }}>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              MyLogo
            </Link>
          </Typography>
        </Box>

        {/* Navigation Buttons */}
        <Button
          color="inherit"
          component={Link}
          to="/client"
          sx={{ marginRight: 2 }}
        >
          Client
        </Button>
        <Button
          color="inherit"
          component={Link}
          to="/property"
        >
          Property
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import ClientList from "./ClientList";

// Define the type for the form data
interface IFormData {
  name: string;
  contact: string;
  comment: string;
  type: string;
  associations: string[]; // Associations as an array of ObjectId strings
}

const ClientForm = () => {
  const [formData, setFormData] = useState<IFormData>({
    name: "",
    contact: "",
    comment: "",
    type: "",
    associations: [], // Initialize as an empty array
  });
  const [propertyOptions, setPropertyOptions] = useState<
    { _id: string; name: string }[]
  >([]);

  // Fetch Property options for associations
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get("/properties"); // Adjust endpoint as needed
        setPropertyOptions(response.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    fetchProperties();
  }, []);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post("/client", formData); // Adjust endpoint as needed
      console.log("Client created:", response.data);
      alert("Client created successfully!");
      setFormData({
        name: "",
        contact: "",
        comment: "",
        type: "",
        associations: [],
      });
    } catch (error) {
      console.error("Error creating client:", error);
      alert("Failed to create client.");
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 4,border: "1px solid #ccc", borderRadius: 2  }}
      >
        <Typography variant="h5" gutterBottom>
          Create Client
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Contact"
          name="contact"
          value={formData.contact}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Comment"
          name="comment"
          value={formData.comment}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          select
          fullWidth
          required
          margin="normal"
        >
          <MenuItem value="lid">Lid</MenuItem>
          <MenuItem value="client">Client</MenuItem>
        </TextField>
        <Autocomplete
          multiple
          options={propertyOptions}
          getOptionLabel={(option) => option.name}
          value={propertyOptions.filter((option) =>
            formData.associations.includes(option._id)
          )}
          onChange={(_, newValue) =>
            setFormData((prev) => ({
              ...prev,
              associations: newValue.map((item) => item._id),
            }))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Associations"
              placeholder="Select properties"
              margin="normal"
              fullWidth
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Create Client
        </Button>
      </Box>

      <ClientList />
    </>
  );
};

export default ClientForm;

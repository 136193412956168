import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
    const [properties, setProperties] = useState([]);
    const [clients, setClients] = useState([]);
    const [keys, setKeys] = useState([]);
    const [newCode, setNewCode] = useState();

    const updateProperties = async () => {
        const response = await axios.get("/property"); // Replace with your API endpoint
        setProperties(response.data);

        const response1 = await axios.get("/property/new-code"); // Replace with your API endpoint
        setNewCode(response1.data.code);
    }

    const updateClients = async () => {

    }

    const updateKeys = async () => {
        const response1 = await axios.get("/value"); // Replace with your API endpoint
        setKeys(response1.data.values);
    }

    useEffect(() => {
        updateKeys();
        updateClients();
        updateProperties();
    }, [])

    return (
        <MainContext.Provider value={{ properties, clients, keys, updateClients, updateKeys, updateProperties, newCode }}>
            {children}
        </MainContext.Provider>
    );
};

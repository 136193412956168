import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { BrowserRouter, HashRouter, Route, Router, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/NavBar';
import PropertyPage from './components/Property/PropertyPage';
import ClientPage from './components/Client/Client';
import axios from 'axios';
import { MainContextProvider } from './MainContext';


const baseURL = "http://localhost:9000/api/";
axios.defaults.baseURL = baseURL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MainContextProvider>
    <HashRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/property' element={<PropertyPage />}/>
        <Route path='/client' element={<ClientPage />}/>
      </Routes>
    </HashRouter>
  </MainContextProvider>
);
import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Card, CardContent, CardMedia, Grid, TextField, Typography, CircularProgress, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Select, InputAdornment, MenuItem, Slider, StepLabel, Checkbox, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import axios from "axios";
import ValueInputComponent from "./ValueInput";
import LocationInput from "./LocationInput";
import NewProp from "./NewProp";
import { MainContext } from "../../MainContext";
import Swal from "sweetalert2";

axios.defaults.baseURL = "http://localhost:9000/api/";

export interface ILocation {
    country: string;
    region: string;
    city: string;
    district: string;
    street: string;
    building: string;
    lat: number;
    lng: number;
    apartment?: string;
}

interface FormInputs {
    description: string;
    price: number;
    action: "rent" | "sell";
    type: "apartment" | "house" | "commercial";
    buildingType: "Stone" | "Panels" | "Monolith" | "Bricks" | "Cassette" | "Wooden";
    status: "pre-owned" | "new";
    propertyStatus: "active" | "stopped" | "sold";
    surface: number;
    rooms: number;
    images: FileList;
    location: ILocation;
    currency: string;
    bathrooms: number;
    ceiling: number;
    renovation: number;
    floor: number;
    totalFloors: number;
    other: any;
}

const PropertyInput: React.FC<{ full: boolean }> = ({ full }) => {
    const [imagePreviews, setImagePreviews] = useState<string[]>([]);
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [imagePreviews_doc, setImagePreviews_doc] = useState<string[]>([]);
    const [imageFiles_doc, setImageFiles_doc] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        getValues,
        control,
        watch,
    } = useForm<FormInputs>({
        defaultValues:{
            propertyStatus:"stopped"
        }
    });

    const { keys, newCode } = useContext(MainContext);

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
        console.log(data);
        try {
            setLoading(true);
            const formData = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if(value){
                    if(typeof value === 'object'){
                        formData.append(key, JSON.stringify(value));
                    }else{
                        formData.append(key, value);
                    }
                }
            })
            formData.append("code", newCode.toString());
            formData.append("filter_doc", JSON.stringify(imageFiles_doc.map((file) => file.name)));
            console.log(imageFiles, imageFiles_doc)
            imageFiles.forEach((file) => formData.append("images", file));
            imageFiles_doc.forEach((file) => formData.append("images", file));
            await axios.post("/property", formData, { headers: { "Content-Type": "multipart/form-data" } });
            // reset();
            // setImageFiles([]);
            // setImagePreviews([]);
        } catch (error) {
            console.error("Error uploading property:", error);
        } finally {
            setLoading(false);
        }
    };

    const onError = (errors:any) => {
        const errorMessages = Object.entries(errors)
        //@ts-ignore
            .map(([field, error]) => `${field}: ${error.message}`)
            .join("\n");

        Swal.fire({
            icon: "error",
            title: "Validation Errors",
            text: "Please fix the following errors before submitting:\n" + errorMessages,
            customClass: {
                container: "swal-error-container",
            },
        });
    };
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const selectedFiles = Array.from(files);
            setImageFiles([...imageFiles, ...selectedFiles]);
            setImagePreviews([...imagePreviews, ...selectedFiles.map((file) => URL.createObjectURL(file))]);
        }
    };

    const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const selectedFiles = Array.from(files);
            setImageFiles_doc([...imageFiles_doc, ...selectedFiles]);
            setImagePreviews_doc([...imagePreviews_doc, ...selectedFiles.map((file) => file.name)]);
        }
    };

    const handleRemoveImage = (index: number) => {
        setImageFiles((prev) => prev.filter((_, i) => i !== index));
        setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    };

    const surfaceValue = watch("surface");
    const roomsValue = watch("rooms");
    const bathroomsValue = watch("bathrooms");
    const ceilingValue = watch("ceiling");
    const floorValue = watch("floor");
    const totalFloorsValue = watch("totalFloors");

    console.log(imagePreviews_doc);
    console.log(errors);

    return (
        <Box component="form" sx={{ p: 3 }} onSubmit={handleSubmit(onSubmit, onError)}>
            <Divider style={{ margin: "30px 0" }}>Main Information</Divider>
            <FormLabel>Code: {newCode}</FormLabel>
            <br />
            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Action</FormLabel>
                <Controller
                    name="action"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup row {...field}>
                            <FormControlLabel value="sell" control={<Radio />} label="Sell" />
                            <FormControlLabel value="rent" control={<Radio />} label="Rent" />
                        </RadioGroup>
                    )}
                />
            </FormControl>
            <br />
            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Property Type</FormLabel>
                <Controller
                    name="type"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup row {...field}>
                            <FormControlLabel value="apartment" control={<Radio />} label="Apartment" />
                            <FormControlLabel value="house" control={<Radio />} label="House" />
                            <FormControlLabel value="commercial" control={<Radio />} label="Commercial" />
                        </RadioGroup>
                    )}
                />
            </FormControl>
            <br />
            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Status</FormLabel>
                <Controller
                    name="status"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <RadioGroup row {...field}>
                            <FormControlLabel value="pre-owned" control={<Radio />} label="Pre-owned" />
                            <FormControlLabel value="new" control={<Radio />} label="New" />
                        </RadioGroup>
                    )}
                />
            </FormControl>
            <br />
            <FormControl component="fieldset" margin="normal">
                <FormLabel style={{ marginBottom: "10px" }}>Building Type</FormLabel>
                <Controller
                    name="buildingType"
                    control={control}
                    render={({ field }) => (
                        <Select defaultValue="..." {...field}>
                            <MenuItem disabled defaultChecked value="...">
                                Select...
                            </MenuItem>
                            <MenuItem value="Stone">Stone</MenuItem>
                            <MenuItem value="Panels">Panels</MenuItem>
                            <MenuItem value="Monolith">Monolith</MenuItem>
                            <MenuItem value="Bricks">Bricks</MenuItem>
                            <MenuItem value="Cassette">Cassette</MenuItem>
                            <MenuItem value="Wooden">Wooden</MenuItem>
                        </Select>
                    )}
                />
            </FormControl>
            <Divider style={{ margin: "30px 0" }}>Location</Divider>
            <LocationInput {...register("location", { required: true })} />
            <Divider style={{ margin: "30px 0" }}>Numbers</Divider>
            <Box sx={{ display: "flex", marginTop: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Property Surface</FormLabel>
                    <Controller
                        name="surface"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                                }}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("surface", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (surfaceValue < 0) setValue("surface", 0);
                                    if (surfaceValue > 1000) setValue("surface", 1000);
                                }}
                            />
                        )}
                    />
                    {/* Slider */}
                    <Controller
                        name="surface"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={surfaceValue || 0}
                                min={0}
                                max={200}
                                step={1}
                                marks={[
                                    { value: 0, label: "0 m²" },
                                    { value: 100, label: "100 m²" },
                                    { value: 200, label: "200 m²" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("surface", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Ceiling</FormLabel>
                    <Controller
                        name="ceiling"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">m</InputAdornment>,
                                }}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("ceiling", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (ceilingValue < 0) setValue("ceiling", 0);
                                    if (ceilingValue > 1000) setValue("ceiling", 1000);
                                }}
                            />
                        )}
                    />
                    {/* Slider */}
                    <Controller
                        name="ceiling"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={ceilingValue || 0}
                                min={1}
                                max={5}
                                step={0.05}
                                marks={[
                                    { value: 1, label: "1 m" },
                                    { value: 2, label: "2 m" },
                                    { value: 3, label: "3 m" },
                                    { value: 4, label: "4 m" },
                                    { value: 5, label: "5 m" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("ceiling", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Rooms</FormLabel>
                    <Controller
                        name="rooms"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("rooms", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (roomsValue < 0) setValue("rooms", 0);
                                    if (roomsValue > 1000) setValue("rooms", 1000);
                                }}
                            />
                        )}
                    />

                    {/* Slider */}
                    <Controller
                        name="rooms"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={roomsValue || 0}
                                min={1}
                                max={4}
                                step={1}
                                marks={[
                                    { value: 1, label: "1" },
                                    { value: 2, label: "2" },
                                    { value: 3, label: "3" },
                                    { value: 4, label: "4+" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("rooms", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Bathrooms</FormLabel>
                    <Controller
                        name="bathrooms"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("rooms", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (bathroomsValue < 0) setValue("bathrooms", 0);
                                    if (bathroomsValue > 1000) setValue("bathrooms", 1000);
                                }}
                            />
                        )}
                    />

                    {/* Slider */}
                    <Controller
                        name="bathrooms"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={bathroomsValue || 0}
                                min={1}
                                max={4}
                                step={1}
                                marks={[
                                    { value: 1, label: "1" },
                                    { value: 2, label: "2" },
                                    { value: 3, label: "3" },
                                    { value: 4, label: "4+" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("bathrooms", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "30px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Floor</FormLabel>
                    <Controller
                        name="floor"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("floor", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (floorValue < 0) setValue("floor", 0);
                                    if (floorValue > 1000) setValue("floor", 1000);
                                }}
                            />
                        )}
                    />
                    {/* Slider */}
                    <Controller
                        name="floor"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={floorValue || 0}
                                min={1}
                                max={50}
                                step={1}
                                marks={[
                                    { value: 1, label: "1" },
                                    { value: 10, label: "10" },
                                    { value: 20, label: "20" },
                                    { value: 30, label: "30" },
                                    { value: 40, label: "40" },
                                    { value: 50, label: "50" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("floor", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginRight: "50px" }}>
                    {/* Number Input */}
                    <FormLabel component="legend">Total Floors</FormLabel>
                    <Controller
                        name="totalFloors"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="number"
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    field.onChange(value); // Update form value
                                    if (value >= 0 && value <= 1000) {
                                        setValue("totalFloors", value); // Sync slider
                                    }
                                }}
                                onBlur={() => {
                                    if (totalFloorsValue < 0) setValue("totalFloors", 0);
                                    if (totalFloorsValue > 1000) setValue("totalFloors", 1000);
                                }}
                            />
                        )}
                    />
                    {/* Slider */}
                    <Controller
                        name="totalFloors"
                        control={control}
                        render={({ field }) => (
                            <Slider
                                {...field}
                                value={totalFloorsValue || 0}
                                min={1}
                                max={50}
                                step={1}
                                marks={[
                                    { value: 1, label: "1" },
                                    { value: 10, label: "10" },
                                    { value: 20, label: "20" },
                                    { value: 30, label: "30" },
                                    { value: 40, label: "40" },
                                    { value: 50, label: "50" },
                                ]}
                                onChange={(_, value) => {
                                    field.onChange(value); // Update form value
                                    setValue("totalFloors", value as number); // Sync number input
                                }}
                                sx={{
                                    "& .MuiSlider-markLabel:nth-child(4)": {
                                        transform: "none", // Keep labels centered
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: 250, marginTop: "50px" }}>
                {/* Number Input */}
                <FormLabel component="legend">Renovation</FormLabel>
                {/* Slider */}
                <Controller
                    name="renovation"
                    control={control}
                    render={({ field }) => (
                        <Slider
                            {...field}
                            min={1}
                            max={7}
                            step={1}
                            marks={[
                                { value: 1, label: "1" },
                                { value: 2, label: "2" },
                                { value: 3, label: "3" },
                                { value: 4, label: "4" },
                                { value: 5, label: "5" },
                                { value: 6, label: "6" },
                                { value: 7, label: "7" },
                            ]}
                            onChange={(_, value) => {
                                field.onChange(value); // Update form value
                                setValue("renovation", value as number); // Sync number input
                            }}
                            sx={{
                                "& .MuiSlider-markLabel:nth-child(4)": {
                                    transform: "none", // Keep labels centered
                                },
                            }}
                        />
                    )}
                />
            </Box>
            <Divider style={{ margin: "30px 0" }}>Properties</Divider>
            <NewProp />
            <Box>
                {keys
                    ?.filter((k: any) => k.type === "checkbox")
                    .map((key: any) => {
                        return (
                            <FormControl component="fieldset" margin="normal">
                                <Controller name={`other.${key.name}`} control={control} render={({ field }) => <FormControlLabel control={<Checkbox  {...register(`other.${key.name}`)}/>} label={key.name} />} />
                            </FormControl>
                        );
                    })}
                <br />
                {keys
                    ?.filter((k: any) => k.type === "string")
                    .map((key: any) => {
                        return <TextField sx={{ margin: "15px 15px 0px 0px" }} label={key.name} margin="normal" {...register(`other.${key.name}`)} />;
                    })}
            </Box>
            <Divider style={{ margin: "30px 0" }}>Descriptions</Divider>
            <TextField label="Description" multiline rows={4} fullWidth margin="normal" {...register("description", { required: true })} />
            <TextField
                label="Price"
                margin="normal"
                type="number"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Controller
                                name="currency"
                                control={control}
                                defaultValue="AMD"
                                render={({ field }) => (
                                    <Select {...field}>
                                        <MenuItem value="AMD">Դ</MenuItem>
                                        <MenuItem value="USD">$</MenuItem>
                                        <MenuItem value="EUR">€</MenuItem>
                                    </Select>
                                )}
                            />
                        </InputAdornment>
                    ),
                }}
                {...register("price", { required: true })}
            />{" "}
            <br />
            <Button variant="outlined" component="label" sx={{ mt: 2, mb: 3 }}>
                + Upload Images
                <input type="file" hidden multiple accept="image/*" onChange={handleImageChange} />
            </Button>
            <Grid container spacing={2} sx={{ mb: 3 }}>
                {imagePreviews.map((src, index) => (
                    <Grid item xs={4} key={index}>
                        <Card>
                            <CardMedia component="img" height="140" image={src} alt={`Preview ${index + 1}`} />
                            <CardContent>
                                <IconButton color="secondary" onClick={() => handleRemoveImage(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Button variant="outlined" color="secondary" component="label" sx={{ mt: 2, mb: 2 }}>
                + Upload Documents
                <input type="file" hidden multiple accept="*" onChange={handleDocumentChange} />
            </Button>
            <Box sx={{ mb: 5, mt: 2 }}>
                {imagePreviews_doc.map((fileName: string, index) => {
                    return (
                        <div>
                            {index + 1}. {fileName}
                        </div>
                    );
                })}
            </Box>
            <FormControl component="fieldset" margin="normal" sx={{ mt: 2, mb: 5 }}>
                <FormLabel style={{ marginBottom: "10px" }}>Status</FormLabel>
                <Controller
                    name="propertyStatus"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select required defaultValue="stopped" {...field}>
                            <MenuItem disabled={!Object.keys(errors).length || !imageFiles_doc.length} value="active">
                                Active
                            </MenuItem>
                            <MenuItem defaultChecked value="stopped">
                                Stopped
                            </MenuItem>
                            <MenuItem value="sold">Sold</MenuItem>
                        </Select>
                    )}
                />
            </FormControl>
            <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Upload Property"}
            </Button>
        </Box>
    );
};

export default PropertyInput;

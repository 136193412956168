import React, { useState, useEffect, useContext } from "react";
import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import axios from "axios";
import { IProperty } from "../../interfaces/property";
import { MainContext } from "../../MainContext";

const baseURL = "http://localhost:9000/api/";
axios.defaults.baseURL = baseURL;

const PropertyList: React.FC = () => {

    const {properties} = useContext(MainContext);

    return (
        <Grid container spacing={4}>
            {properties.map((property: IProperty) => (
                <Grid item xs={12} sm={6} md={4} key={property._id}>
                    <Card>
                        {property.images[0] && <CardMedia component="img" height="200" image={baseURL + property.images[0]} alt={property.description} />}
                        <CardContent>
                            <Typography variant="h6">{property.description}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                Price: ${property.price}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Type: {property.type === "sell" ? "For Sale" : "For Rent"}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Uploaded: {new Date(property.createdAt).toLocaleDateString()}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default PropertyList;

import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";

interface IClient {
  _id: string;
  name: string;
  contact: string;
  comment: string;
  type: string;
  associations: string[];
}

const ClientList = () => {
  const [clients, setClients] = useState<IClient[]>([]);

  // Fetch clients from the backend
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("/client"); // Adjust endpoint as needed
        setClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, []);

  // Handle client deletion
  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`/api/clients/${id}`); // Adjust endpoint as needed
      setClients((prev) => prev.filter((client) => client._id !== id));
      alert("Client deleted successfully!");
    } catch (error) {
      console.error("Error deleting client:", error);
      alert("Failed to delete client.");
    }
  };

  return (
    <Box sx={{ mt: 4, mx: "auto", maxWidth: 800 }}>
      <Typography variant="h5" gutterBottom>
        Client List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Associations</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client._id}>
                <TableCell>{client.name}</TableCell>
                <TableCell>{client.contact}</TableCell>
                <TableCell>{client.comment}</TableCell>
                <TableCell>{client.type}</TableCell>
                <TableCell>
                  {client.associations.length > 0
                    ? client.associations.join(", ")
                    : "No Associations"}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(client._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientList;

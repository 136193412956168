import React, { useState, useEffect } from "react";
import { Box, Button, Card, CardContent, CardMedia, Grid, TextField, Typography, CircularProgress, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Collapse } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import ValueInputComponent from "./ValueInput";
import { IProperty } from "../../interfaces/property";
import PropertyList from "./PropertyList";
import PropertyInput from "./PropertyInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface FormInputs {
    description: string;
    price: number;
    type: "rent" | "sell";
    images: FileList;
}
interface CollapsableBoxProps {
    title: string;
    children: React.ReactNode; // Accept children as a prop
}

const CollapsableBox: React.FC<CollapsableBoxProps> = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box sx={{ mb: 4, border: "2px solid #ccc", borderRadius: 3 }}>
            <Box
                onClick={() => setIsOpen(!isOpen)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 2,
                    cursor: "pointer",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 3,
                }}
            >
                <Typography variant="h6">{title}</Typography>
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
            <Collapse in={isOpen}>
                <Box sx={{ padding: 2 }}>{isOpen ? children : ''}</Box>
            </Collapse>
        </Box>
    );
};

const PropertyPage: React.FC = () => {
    const [loading, setLoading] = useState(false);

    // Fetch properties from the backend
    const fetchProperties = async () => {
        try {
            setLoading(true);

        } catch (error) {
            console.error("Error fetching properties:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom>
                Property Manager
            </Typography>
            <CollapsableBox title="Upload a New Property">
                <PropertyInput full={false}/>
            </CollapsableBox>
            <Typography variant="h6" gutterBottom>
                Existing Properties
            </Typography>
            <CollapsableBox title="Filter">
                <PropertyInput full={false}/>
            </CollapsableBox>
            <PropertyList />
        </Box>
    );
};

export default PropertyPage;
